import './Loader.scss';
import 'react-circular-progressbar/dist/styles.css';

import {Box, Container, Text, VStack} from "@chakra-ui/react";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";

function Loader() {
    const intl = useIntl();

    let [percentage, setPercentage] = useState(0);
    let [text, setText] = useState(intl.formatMessage({id: 'loader.initialText'}));

    let navigate = useNavigate();

    useEffect(() => {
        if (percentage > 60) {
            setText(intl.formatMessage({id: 'loader.text'}));
        }

        if (percentage === 100) {
            navigate('../complete', {replace: true});
        }

        if (percentage < 100) {
            const interval = setInterval(() => {
                setPercentage(percentage => percentage + 10);
            }, 300);
            return () => clearInterval(interval);
        }
    }, [intl, percentage, navigate]);

    return (
        <Container display={'flex'} height={'80%'} alignItems={'center'} justifyContent={'center'}>
            <VStack>
                <Box width={200} height={200} mb={5}>
                    <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        background={'#fff'}
                        styles={buildStyles({
                            pathColor: `rgba(208, 21, 28, ${percentage / 100})`,
                            textColor: "#000",
                            backgroundColor: "#fff"
                        })}
                    />
                </Box>
                <Text px={30} color={'white'} fontSize={'large'} fontWeight={'bold'} textAlign={'center'}>
                    {text}
                </Text>
            </VStack>
        </Container>
    )
}

export default Loader;
