import "./Alviona.scss";
import '@fontsource/inter/700.css';
import '@fontsource/inter/400.css'
import Logo from './Logo.svg';
import {Box, Image} from "@chakra-ui/react";

function AlvionaTemplate(props) {
    return (
        <div className={"page alviona"}>
            <Box pt={[1,5]} pb={[0,2]} textAlign={'center'}>
                <Image src={Logo} height={[10,20]} display={'inline-block'} />
            </Box>

            {props.children}
        </div>
    );
}

export default AlvionaTemplate;
