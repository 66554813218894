import Step from "../../components/step/Step";
import {Button} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {SuccessStory} from "../../components/success-story/SuccessStory";
import success1 from "../../assets/step4/success2.gif";
import {FormattedMessage, useIntl} from "react-intl";

function Step3Extra() {
    let navigate = useNavigate();
    const intl = useIntl();

    return (
        <Step currentStep={3}
              backLink={'/step4'}>

            <SuccessStory title={intl.formatMessage({id: 'step3.headline'})}
                          description={intl.formatMessage({id: 'step3.text'})}
                          image={success1}/>

            <Button bg="brand"
                    color={'white'}
                    size="md"
                    mt={5} w={'100%'}
                    onClick={() => {
                        navigate('../step6', {replace: true})
                    }}
                    type="submit">
                <FormattedMessage id="form.button.next" />
            </Button>
        </Step>
    );
}

export default Step3Extra;
